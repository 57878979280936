import {followPath, splitPropertyPath, splitModulePathSegments, defaultSetterName} from './common'

export function mapFormFields (modulePath, fields) {
  if (typeof modulePath !== 'string') {
    fields = modulePath
    modulePath = ''
  }

  const modulePathSegments = modulePath ? splitModulePathSegments(modulePath) : []

  const resolveModule = vm => followPath(vm.$store.state, modulePathSegments.map(x => {
    if (vm.modulePath) {
      return x.replace('{modulePath}', vm.modulePath)
    } else {
      return x
    }
  }))

  const computed = {}

  if (Array.isArray(fields)) {
    fields = fields.reduce((obj, path) => Object.assign(obj, {[path]: {}}), {})
  }

  for (const field in fields || {}) {
    const [fieldPath, propertyName] = splitPropertyPath(field)

    const options = fields[field]
    const {read, write, propertyName: propertyNameOverride} = options

    const mutation = typeof options === 'string'
      ? options
      : options.mutation
        ? options.mutation
        : defaultSetterName(propertyName)

    const fullMutationName = modulePath
      ? `${modulePath}/${mutation}`
      : mutation

    computed[propertyNameOverride || propertyName] = {
      get: read
        ? function () {
          const state = resolveModule(this)
          const getters = modulePath
            ? Object.keys(this.$store.getters)
              .filter(key => key.startsWith(`${modulePath}/`))
              .reduce((getters, key) => Object.assign(getters, {[key.replace(new RegExp(`^${modulePath}/`), '')]: this.$store.getters[key]}), {})
            : this.$store.getters

          const value = followPath(state, fieldPath)[propertyName]
          return read.call(this, value, state, getters)
        }
        : function () {
          const state = resolveModule(this)
          return followPath(state, fieldPath)[propertyName]
        },
      set (value) {
        const specificFullMutationName = fullMutationName.includes('{modulePath}') ? fullMutationName.replace('{modulePath}', this.modulePath) : fullMutationName
        this.$store.commit(specificFullMutationName, write ? write.call(this, value) : value)
      }
    }
  }
  return computed
}

export const modelProxy = {
  get () {
    return this.$options.model && this.$options.model.prop ? this[this.$options.model.prop] : this.value
  },
  set (value) {
    if (this.$options.model && this.$options.model.event) {
      this.$emit(this.$options.model.event, value)
    } else {
      this.$emit('input', value)
    }
  }
}
