import {toObject} from './reducers'

export const map = (obj, f) => Object.keys(obj)
  .map(key => [key, f(obj[key], key, obj)])
  .reduce(toObject, {})

export const filter = (obj, f) => Object.keys(obj)
  .map(key => [obj[key], key, obj])
  .filter(args => (f instanceof Function ? f : (value, key) => key in f)(...args))
  .map(([value, key]) => [key, value])
  .reduce(toObject, {})

export const invert = (obj) => Object.keys(obj)
  .map(key => [obj[key], key])
  .reduce(toObject, {})
