import {replaceAliases} from '@helpers/i18n'

const isVueInternal = (key) => typeof key !== 'string' || key === 'toJSON' || key === '_isVue' || key === '_vm' || key === 'render' || key === 'state'

const createProxy = (alias, resource, store) => new Proxy(store.state.i18n, {
  ownKeys (state) {
    return Object.keys(state.translations[resource] || {})
  },
  get (state, key) {
    if (key === '_alias') {
      return alias
    }
    if (key === '_resource') {
      return resource
    }
    const existing = state.translations[resource] ? state.translations[resource][key] : undefined
    if (existing === undefined && !isVueInternal(key)) {
      store.dispatch('i18n/signalMissingTranslation', {namespace: resource, key})
    }
    return existing
  }
})

export function normalizeMap (map) {
  return Array.isArray(map)
    ? map.map(key => ({key, val: key}))
    : Object.keys(map).map(key => ({key, val: map[key]}))
}

/*
Usage:

import {mapResources} from '@helpers/vuex'

export default {
  computed: {
    // filename is used as property name
    ...mapResources([
      'Areas.FieldRecordSystem.SR_FieldRecordSystem'
    ]),
    ...mapResources('Areas.FieldRecordSystem', [
      'SR_FieldRecordSystem',
      'SR_FieldRecordSystemSubResource'
    ]),
    // custom property name
    ...mapResources({
      SR_FRS: 'Areas.FieldRecordSystem.SR_FieldRecordSystem'
    }),
    ...mapResources('Areas.FieldRecordSystem', {
      SR_FRS: 'SR_FieldRecordSystem'
    }),
    // predefined global aliases for common paths
    ...mapResources([
      '@frs.SR_FieldRecordSystem' // resolves to 'Areas.FieldRecordSystem.SR_FieldRecordSystem'
    ])
  }
}
*/
export const mapResources = (prefix, resources) => {
  if (!window.Proxy) {
    throw new Error('ES6 Proxy not supported, localization mixin will not work correctly')
  }

  if (typeof prefix !== 'string') {
    resources = prefix
    prefix = ''
  }

  resources = Array.isArray(resources)
    ? resources.map(resource => ({propertyName: resource.split('.').slice(-1)[0], resource}))
    : Object.keys(resources).map(propertyName => ({propertyName, resource: resources[propertyName]}))

  const computed = {}

  for (const {propertyName, resource} of resources) {
    const fullResource = replaceAliases(prefix ? `${prefix}.${resource}` : resource)

    computed[propertyName] = function () {
      return window.Proxy ? createProxy(propertyName, fullResource, this.$store) : {}
    }
  }

  return computed
}
