<template lang="html">
  <div style="position: relative">
    <form v-if="soilDetails" @submit.prevent>
      <div v-if="soilTypes" class="form-group soil-type">
        <VueSelectize
          v-model="soilType"
          allow-empty
          :fields="{text: 'name', value: 'name'}" required
          :options="soilTypes" sort-field="text"
          placeholder="Bitte Bodentyp auswählen" name="soilType"
        >
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SoilType</IxRes>
          </template>
        </VueSelectize>
      </div>

      <div v-if="soilSubTypes" class="form-group soil-subtype">
        <VueSelectize
          v-model="soilSubType"
          allow-empty
          :fields="{text: 'name', value: 'name'}" required
          :options="soilSubTypes" sort-field="text"
          placeholder="Bitte Bodenart auswählen" name="soilSubType"
        >
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SoilSubType</IxRes>
          </template>
        </VueSelectize>
      </div>

      <div class="form-group soil-quality">
        <NumericInput
          v-model="soilQuality" :info-description="SR_FieldRecordSystem.SoildQualityTitle"
          :rule="between(0, 120)" integer
          name="soilQuality"
        >
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.SoilQuality</IxRes>
          </template>
        </NumericInput>
      </div>

      <div class="form-group usabel-field-capacity">
        <NumericInput
          v-model="usableFieldCapacity" :info-description="SR_FieldRecordSystem.UsableFieldCapacityTitle"
          :rule="between(0, 500)" unit="mm"
          integer name="usableFieldCapacity"
        >
          <template #label>
            <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.UsableFieldCapacity</IxRes>
          </template>
        </NumericInput>
      </div>

      <hr>
      <div class="pull-right">
        <IxButton
          large :disabled="canSave"
          save
          @click="save"
        />
        <IxButton large cancel @click="openModal" />
      </div>
    </form>

    <div v-else class="alert alert-info">
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.NoDetailsForSelectedObject</IxRes>
    </div>

    <FrsLoadingIndicator :loading="loading || saving" />

    <BsModal v-model="showModal">
      <template #title>
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EditSoilDetails</IxRes>
      </template>
      <div class="alert alert-info">
        <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.EditSoilModalCancel</IxRes>
      </div>
      <button slot="footer" class="btn btn-success" @click="confirmModal">
        <IxRes>Common.SR_Common.Confirm</IxRes>
      </button>
      <button slot="footer" class="btn btn-danger" @click="hideModal">
        <IxRes>Common.SR_Common.Cancel</IxRes>
      </button>
    </BsModal>
  </div>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'

import {mapResources, mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import VueSelectize from '@components/VueSelectize'
import NumericInput from '@components/forms/NumericInput'
import IxButton from '@components/IxButton'
import BsModal from '@components/bootstrap/BsModal'

import FormPartMixin from '@components/forms/FormPartMixin'
import StandardRulesMixin from '@components/forms/StandardRulesMixin'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

export default {
  components: {
    VueSelectize,
    NumericInput,
    IxButton,
    BsModal,
    FrsLoadingIndicator
  },
  mixins: [
    StandardRulesMixin,
    FormPartMixin
  ],
  data () {
    return {
      showModal: false,
      loading: false,
      saving: false
    }
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/soilDetail/editBuffer', [
      'soil.soilQuality',
      'soil.usableFieldCapacity'
    ]),
    ...mapFormFields('fieldRecordSystem/soilDetail/editBuffer', {
      'soil.soilType': {
        read (value) {
          return this.soilTypes.find(s => s.name === value) || null
        },
        write: value => value ? value.name : null
      },
      'soil.soilSubType': {
        read (value) {
          return this.soilSubTypes.find(s => s.id === value) || null
        },
        write: value => value ? value.id : null
      }
    }),
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapState('fieldRecordSystem', {
      soilTypes: state => state.data.soilTypes,
      soilSubTypes: state => state.data.soilSubTypes,
      soilDetails: state => state.soilDetail.editBuffer,
      harvestYear: state => state.userSettings.harvestYear
    }),
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    disableSaveButton () {
      return this.state !== 'success'
    },
    canSave () {
      return !(this.soilType && this.soilSubType)
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem', [
      'loadFullFieldData'
    ]),
    ...mapActions('fieldRecordSystem/soilDetail', [
      'saveSoilData',
      'reset'
    ]),
    confirmModal () {
      this.hideModal()
      this.$nextTick(() => {
        this.$router.push({name: 'fieldDashboard'})
      })
    },
    async save () {
      this.saving = true

      this.validate()

      // nextTick to get computed updates for state
      await this.$nextTick()
      if (this.state !== 'success') return

      try {
        await this.saveSoilData()
        notifications.success(this.SR_FieldRecordSystem.SoilConditionSaveSuccess)
        this.$router.push({name: 'fieldDashboard'})
      } catch (error) {
        notifications.error(this.SR_FieldRecordSystem.SoilConditionSaveError)
      } finally {
        this.saving = false
      }
    },
    openModal () {
      this.showModal = !this.showModal
    },
    hideModal () {
      this.showModal = false
    },
    async prepareData (fieldId) {
      this.loading = true
      await this.loadFullFieldData(fieldId)
      await this.reset(fieldId)
      this.loading = false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      await vm.prepareData(to.params.fieldId)
    })
  },
  async beforeRouteUpdate (to, from, next) {
    next()
    await this.prepareData(to.params.fieldId)
  }
}
</script>

<style lang="scss">
.soil-quality .input-field, .pH .input-field {
  width: 25%;
}
.usabel-field-capacity .input-group, .water-level .input-group, .humus-content .input-group {
  width: 33%;
}
</style>

<style lang="scss" scoped>
div.form-group {
  padding: 0 0px;
}
div.alert {
  padding: 5px;
  height: 34px;
}
.soil-type, .soil-subtype {
  width: 60%;
}
</style>
